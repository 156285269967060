<template>
  <b-card>
    <div class="text-right">
      <b-button size="sm" variant="warning" @click="goToHome"> <b-icon-arrow-left /> Regresar</b-button>
    </div>
    <b-overlay :show="isLoadingBenefit || isUpdatingBenefit" rounded class="">
      <ValidationObserver ref="updateBenefitForm" v-slot="{ invalid }" v-if="selectedBenefit">
        <b-form @submit.prevent="update">

          <b-tabs class="mb-1">
            <b-tab title="General">
              <b-row>
                <b-col md="12">
                  <b-alert variant="warning" show v-if="selectedBenefit.temporalDataStatus">
                    <div class="alert-body text-center">
                      <span> Pendiente por autorizar </span>
                    </div>
                  </b-alert>
                </b-col>

                <b-col md="3">
                  <ValidationProvider rules="required" name="codigo">
                    <b-form-group label="* Código" slot-scope="{ valid, errors }">
                      <b-form-input
                        class="form-control"
                        type="text"
                        :disabled="selectedBenefit.temporalDataStatus"
                        v-model="selectedBenefit.code"
                        :state="errors[0] ? false : valid ? true : null"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col md="3">
                  <ValidationProvider rules="required" name="nombre">
                    <b-form-group label="* Nombre" slot-scope="{ valid, errors }">
                      <b-form-input
                        class="form-control"
                        type="text"
                        :disabled="selectedBenefit.temporalDataStatus"
                        v-model="selectedBenefit.name"
                        :state="errors[0] ? false : valid ? true : null"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col md="3">
                  <ValidationProvider rules="required" name="nameEng">
                    <b-form-group
                      label="* Nombre en inglés"
                      slot-scope="{ valid, errors }"
                    >
                      <b-form-input
                        class="form-control"
                        type="text"
                        :disabled="selectedBenefit.temporalDataStatus"
                        v-model="selectedBenefit.nameEng"
                        :state="errors[0] ? false : valid ? true : null"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="3">
                  <ValidationProvider rules="required" name="Tipo Beneficio">
                    <b-form-group label="Tipo Beneficio" slot-scope="{ valid, errors }">
                      <b-form-select
                        :state="errors[0] ? false : valid ? true : null"
                        :disabled="selectedBenefit.temporalDataStatus"
                        v-model="selectedBenefit.typesBenefits"
                      >
                        <option value="">Tipo de beneficio</option>
                        <option
                          v-for="typeBenefit in typesBenefits"
                          :key="typeBenefit.id"
                          :value="typeBenefit.id"
                        >
                          {{ typeBenefit.name }}
                        </option>
                      </b-form-select>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col md="3">
                  <ValidationProvider rules="" name="Grupo Tipo Beneficio">
                    <b-form-group label="Grupo Tipo Beneficio">
                      <b-form-select
                        :disabled="selectedBenefit.temporalDataStatus"
                        v-model="selectedBenefit.groupsTypesBenefits"
                        @change="unsetNightTypesBenefits"
                      >
                        <option value="">Grupo tipo de beneficio</option>
                        <option
                          v-for="groupTypeBenefit in groupsTypesBenefits"
                          :key="groupTypeBenefit.id"
                          :value="groupTypeBenefit.id"
                        >
                          {{ groupTypeBenefit.name }}
                        </option>
                      </b-form-select>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="3">
                  <ValidationProvider name="Forma de pago">
                    <b-form-group label="Forma de pago">
                      <b-form-select
                        :disabled="selectedBenefit.temporalDataStatus"
                        v-model="selectedBenefit.fp"
                      >
                        <option :value="null">Seleccione forma de pago</option>
                        <option
                          v-for="fp in paymentMethods"
                          :key="fp.id"
                          :value="fp.id"
                        >
                          {{ fp.name }}
                        </option>
                      </b-form-select>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col md="3">
                  <ValidationProvider rules="required" name="origen">
                    <b-form-group label="Origen" slot-scope="{ valid, errors }">
                      <b-form-select
                        :state="errors[0] ? false : valid ? true : null"
                        :disabled="selectedBenefit.temporalDataStatus"
                        v-model="selectedBenefit.origin"
                      >
                        <option value="">Origen</option>
                        <option v-for="fp in origins" :key="fp.id" :value="fp.id">
                          {{ fp.name }}
                        </option>
                      </b-form-select>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>

              <hr>

              <b-row>
                <b-col md="1">
                  <div class="float-left pt-1">
                    <b-form-checkbox
                      :disabled="selectedBenefit.temporalDataStatus"
                      v-model="selectedBenefit.status"
                      class="custom-control-success"
                      :value="true"
                      :unchecked-value="false"
                    > Activo
                    </b-form-checkbox>
                  </div>
                </b-col>
                <b-col md="">
                  <div class="float-left pt-1">
                    <b-form-checkbox
                      :disabled="selectedBenefit.temporalDataStatus"
                      v-model="selectedBenefit.includebalance"
                      :value="true"
                      :unchecked-value="false"
                    > Incluir en el reporte de Saldo de Beneficios
                    </b-form-checkbox>
                  </div>
                </b-col>
                <b-col md="">
                  <div class="float-left pt-1">
                    <b-form-checkbox
                      :disabled="selectedBenefit.temporalDataStatus"
                      v-model="selectedBenefit.includebooking"
                      :value="true"
                      :unchecked-value="false"
                    > Incluir en la sección de booking
                    </b-form-checkbox>
                  </div>
                </b-col>
                <b-col md="">
                  <div class="float-left pt-1">
                    <b-form-checkbox
                      class="custom-control-success"
                      :disabled="selectedBenefit.temporalDataStatus"
                      v-model="selectedBenefit.visibleOnWeb"
                      :value="true"
                      :unchecked-value="false"
                    >
                      Visualizar en la Web de Propietarios
                    </b-form-checkbox>
                  </div>
                </b-col>
                <b-col md="">
                <div class="float-left pt-1">
                  <b-form-checkbox
                    class="custom-control-success"
                    v-model="selectedBenefit.includeContract"
                    :value="true"
                    :unchecked-value="false"
                  >
                    Incluir en el contrato
                  </b-form-checkbox>
                </div>
              </b-col>
              <b-col md="">
                <div class="float-left pt-1">
                  <b-form-checkbox
                    class="custom-control-success"
                    v-model="selectedBenefit.includedInSubscription"
                    :value="true"
                    :unchecked-value="false"
                  >
                    Disponible para suscripción
                  </b-form-checkbox>
                </div>
              </b-col>
                <b-col md="12" v-if="selectedBenefit.groupsTypesBenefits == 1">
                  <div class="float-left pt-2 mt-1">
                    <label for="radio-options">Seleccione tipo de noches</label>
                    <b-form-radio-group
                      :disabled="selectedBenefit.temporalDataStatus"
                      v-model="selectedBenefit.nightTypesBenefits"
                      :options="options"
                      name="radio-options"
                      stacked
                    />
                  </div>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="Productos">
              <b-row class="mt-1 mb-1 border rounded p-1">
                <b-col md="12">
                  <div class="d-flex justify-content-between">
                    <h4>Búsqueda de Productos</h4>
                  </div>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Lista de categorias">
                    <b-form-select
                      v-model="categories"
                      :disabled="selectedBenefit.temporalDataStatus"
                    >
                      <option value="">Categorias</option>
                      <option
                        v-for="cat in categoriesProducts"
                        :key="cat.id"
                        :value="cat.id"
                      >
                        {{ cat.name }}
                      </option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Productos">
                    <b-form-input
                      class="form-control"
                      type="text"
                      :disabled="selectedBenefit.temporalDataStatus"
                      v-model="nameProduct"
                    />
                  </b-form-group>
                </b-col>
                <b-col class="w-auto">
                  <div class="text-nowrap">
                    <b-form-group label="Acciones">
                      <b-button :disabled="validNull" variant="warning" class="mr-1" @click="resetFilter()" >Reset </b-button>
                      <b-button :disabled="validNull"  variant="primary" @click="searchByFilter()">Buscar </b-button>
                    </b-form-group>
                  </div>
                </b-col>

                <b-col md="12" class="mt-1">
                  <b-table
                    v-if="!isLoadingProducts && benefitsProducts.length > 0"
                    responsive
                    :items="dataBenefitsProducts"
                    :fields="productFields"
                    :filter="filterBenefitsProducts"
                    :filter-included-fields="['detaildisplay']"
                    class="mb-0"
                    show-empty
                    sticky-header="20rem"
                    empty-text="No hay registros para mostrar"
                  >
                    <template #cell(actions)="row">
                      <div>
                        <b-form-checkbox
                          :disabled="selectedBenefit.temporalDataStatus"
                          v-model="row.item.status"
                          switch
                          inline
                          :value="true"
                          :unchecked-value="false"
                          class="custom-control-success"
                        />
                      </div>
                    </template>
                  </b-table>

                  
                  <div class="text-center mt-1" v-if="isLoadingProducts && !filterEmpty">
                    <b-spinner/><br />
                    <strong >Cargando Datos</strong>
                  </div>
                  <div class="text-center mt-1" v-else-if="!isLoadingProducts && filterEmpty">
                    <b-alert :show="!isLoadingProducts && filterEmpty" variant="danger" >
                      <div class="alert-body">
                        <feather-icon
                          class="mr-25"
                          icon="InfoIcon"
                        />
                        <span class="ml-25">No hay productos con beneficios.</span>
                      </div>
                    </b-alert>
                  </div>
                </b-col>
              </b-row>

              <b-row
                class="mt-1 mb-1 border rounded p-1 infoBg"
                v-if="selectedBenefit.product.length > 0"
              >
                <b-col md="12 ">
                  <h4>Productos</h4>
                    <b-table
                      responsive
                      :items="selectedBenefit.product"
                      :fields="productFields"
                      class="mb-0"
                      show-empty
                      sticky-header="20rem"
                      empty-text="No hay registros para mostrar"
                    >
                      <template #cell(actions)="item">
                        <div>
                          <b-form-checkbox
                            :disabled="selectedBenefit.temporalDataStatus"
                            v-model="item.item.status"
                            switch
                            inline
                            :value="true"
                            :unchecked-value="false"
                            class="custom-control-success"
                          />
                        </div>
                      </template>
                    </b-table>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>

          <b-row>
            <b-col>
              <div class="float-right">
                <b-button
                  v-if="!selectedBenefit.temporalDataStatus"
                  type="submit"
                  class="btn-block"
                  variant="primary"
                  :disabled="invalid || isUpdatingBenefit"
                > <b-spinner small v-if="isUpdatingBenefit " /> Actualizar
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-overlay>
  </b-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { showAlertMessage, sweetAlert, isANumber } from '@/helpers/helpers'
import { origins } from "@/data/fivesClub";
import { createArraysToSaveProducts } from "@/helpers/fivesClubHelper";
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
  },
  async mounted() {
    await this.init()
  },
  data() {
    return {
      idBenefit: this.$route.params.idBenefit,
      origins,
      isUpdatingBenefit: false,
      isLoadingBenefit: false,
      filterEmpty: false,
      filterBenefitsProducts: "",
      beforeSearch: false,
      categories: "",
      nameProduct: null,
      isDeletingtransaction: false,
      productFields: [
        { key: "detaildisplay", label: "Nombre", class: "font-weight-bolder w-100" },
        { key: "actions", label: "Usar", class: "" },
      ],
      options: [
        { text: 'Noches por Contrato', value: 'isNightsContract' },
        { text: 'Noches por Temporada Alta', value: 'isNightsTA' },
        { text: 'Noches Extra', value: 'isNightsXtra' }
      ],
      isLoadingProducts: false,
      benefitsProducts: []
    };
  },
  computed: {
    ...mapState("fivesClubCatalogs", [
      "typeMemberships",
      "selectedBenefit",
      "typesBenefits",
      "groupsTypesBenefits",
      "paymentMethods",
      "categoriesProducts",
    ]),
    ...mapState("auth", ["user"]),
    dataBenefitsProducts() {
      return this.benefitsProducts
    },
    validNull() {
      let validData = this.categories == ""
      return validData
    },
  },
  methods: {
    ...mapActions("fivesClubCatalogs", [
      "getInitialContentForBenefits",
      "updateBenefit",
      "fetchBenefits",
      "fetchBenefitInfo",
      "fetchBenefitsProducts",
    ]),
    ...mapMutations("fivesClubCatalogs", [
      "setSelectedBenefit",
      "setBenefits",
    ]),
    async init(){
			if(isANumber(this.idBenefit)){
        this.isLoadingBenefit = true
        await this.getInitialContentForBenefits({
          typeMemberships: !this.typeMemberships?.length > 0,
          typesBenefits: !this.typesBenefits?.length > 0,
          groupsTypesBenefits: !this.groupsTypesBenefits?.length > 0,
          paymentTypes: !this.paymentMethods?.length > 0,
          categoriesList: !this.categoriesProducts?.length > 0,
        }) // aqui pido hotels, languages y nationalities

        const response = await this.fetchBenefitInfo({id: this.idBenefit}) // descomentar cuando se cambie en el back

        if (response != []){
          this.setSelectedBenefit(structuredClone(response))
        } else this.exitView()

        this.isLoadingBenefit = false
      }
    },
    async update() {
      this.isUpdatingBenefit = true;
      const newProducts = this.dataBenefitsProducts.filter(pr => !!pr.status)
      const prId = createArraysToSaveProducts(this.selectedBenefit.product.concat(newProducts))
      const payload = {
        code: this.selectedBenefit.code,
        idUser: this.user.idUser,
        id: this.selectedBenefit.id,
        idTypeBenefit: this.selectedBenefit.typesBenefits,
        idGroupBenefit: this.selectedBenefit.groupsTypesBenefits,
        name: this.selectedBenefit.name,
        nameEng: this.selectedBenefit.nameEng,
        fp: this.selectedBenefit.fp,
        product: prId,
        origin: this.selectedBenefit.origin,
        status: this.selectedBenefit.status,
        includeBalance: this.selectedBenefit.includebalance,
        includeBooking: this.selectedBenefit.includebooking,
        visibleOnWeb: this.selectedBenefit.visibleOnWeb,
        includeContract: this.selectedBenefit.includeContract,
        includedInSubscription: this.selectedBenefit.includedInSubscription,
      };

      try {
        const { status, message } = await this.updateBenefit(payload);
        if (status) {
          showAlertMessage("Ok: proceso finalizado", "InfoIcon", message, "success", 4000, "bottom-right")
          this.$refs.updateBenefitForm.reset(); //reseteo form
          this.setSelectedBenefit(null);
          this.benefitsProducts = []
          //vuelvo a pedir la info del back
          this.exitView()
        }
      } catch (error) {
        showAlertMessage("Error", "InfoIcon", `Hubo un error al actualizar el beneficio, intente más tarde. ${error}`, "danger", 4000, "bottom-right")
      }
      this.isUpdatingBenefit = false;
    },
    async searchByFilter() {
      this.isLoadingProducts = true
      this.benefitsProducts = []
      this.filterEmpty = false
      const payload = {
        idBenefit: this.idBenefit,
        name: this.nameProduct,
        categories: this.categories,
      }

      if(!payload.name ){
        delete payload.name
      }
      const listProducts = await this.fetchBenefitsProducts(payload);
      this.benefitsProducts = listProducts.map(prods => {
        return {
          ...prods,
          status: false
        }
      })

      this.filterEmpty= this.benefitsProducts.length == 0
      this.isLoadingProducts = false
    },
    resetFilter(){
      this.benefitsProducts = []
      this.nameProduct = null
      this.categories = ''
      this.filterEmpty = false
    },
    exitView(){
      this.resetFilter()
      this.setSelectedBenefit(null)
      this.goToHome()
    },
    goToHome(){
      this.$router.push({ name: 'benefits' })
    },
    unsetNightTypesBenefits(){
      if (this.selectedBenefit.groupsTypesBenefits != 1) this.selectedBenefit.nightTypesBenefits = null
    }
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.tbodyDiv {
  max-height: 20rem;
  overflow: auto;
}

.tbodyDivProduct {
  max-height: 20rem;
  overflow: auto;
}
</style>